import React from 'react';
import styled, { css } from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';

import SocialProofBackground from '../../assets/img/SocialProofBackground.png';

import ArrowIcon from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const UNCFDLogo = '../../assets/img/UNCDFLogo.png';
const WEFLogo = '../../assets/img/WEFLogo.png';

const Container = styled.div`
  background-image: url(${SocialProofBackground});
  background-size: cover;
  width: 100%;
  min-height: 200px;
  background-position: ${props => props.mobile && 'center'};
`;
const MainWrap = styled.div`
  padding-left: ${props => (props.mobile ? '0.5rem' : '1rem')};
  padding-right: ${props => (props.mobile ? '0.5rem' : '1rem')};
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -20px;
`;
const RowWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.marginTop && '1rem'};
  @media (max-width: 760px) {
    flex-direction: column;
    margin-top: ${props => props.marginTop && '3rem'};
  }
`;
const BrandWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-left: ${props => (props.mobile ? '1rem' : '2rem')};
  margin-right: ${props => (props.mobile ? '1rem' : '2rem')};

  @media (max-width: 760px) {
    width: 79%;
    margin: 1rem auto;
  }
`;
const Tile = styled(Paper)`
  width: 239px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 64px;
  &:last-child {
    margin-right: 0;
  }
  min-height: ${props => props.mobile && '80px'};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
  border-radius: 20px !important;
  @media (max-width: 760px) {
    margin-top: 27px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &:first-child {
      margin-top: 0;
    }
  }
`;
const TileTitle = styled.div`
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 71px;
  font-weight: 700;
  @media (max-width: 760px) {
    font-size: 42px;
    line-height: 57px;
  }
  color: ${props =>
    props.isPurple
      ? `${colors.secondary}`
      : props.isTeal
      ? `${colors.primary}`
      : `${colors.black}`};
`;
const TileDescription = styled.div`
  font-size: ${fontSize.large};
  text-align: ${props => props.mobile && 'center'};
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  ${props =>
    props.brand &&
    `
      @media (max-width: 760px) {
        font-family: 'Open Sans', sans-serif !important;
        line-height: 18px;
        font-weight: normal;
        margin-right: 8px;
      }
    `}
`;
const BrandIcon = styled.div`
  width: 65px;
  margin-right: 1rem;

  @media (max-width: 760px) {
    width: 53px;
  }
`;
const StyledArrowIcon = styled(ArrowIcon)`
  font-size: 1rem !important;
  margin-left: ${props => props.desktop === 'true' && '0.5rem'};
`;
const StyledLink = styled.a`
  text-decoration: none;
  color: ${colors.black};
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SocialProofSection = props => {
  const { mobile, verifiedGogetters, ratings, activeUsers } = props;

  return (
    <Container mobile={mobile ? 'true' : undefined}>
      <MainWrap mobile={mobile ? 'true' : undefined}>
        <RowWrap>
          <Tile mobile={mobile ? 'true' : undefined}>
            <TileTitle isPurple mobile={mobile ? 'true' : undefined}>
              {activeUsers}K+
            </TileTitle>
            <TileDescription mobile={mobile ? 'true' : undefined}>
              Users
            </TileDescription>
          </Tile>
          <Tile mobile={mobile ? 'true' : undefined}>
            <TileTitle isTeal mobile={mobile ? 'true' : undefined}>
              {ratings}%
            </TileTitle>
            <TileDescription mobile={mobile ? 'true' : undefined}>
              Good Ratings
            </TileDescription>
          </Tile>
          <Tile mobile={mobile ? 'true' : undefined}>
            <TileTitle mobile={mobile ? 'true' : undefined}>
              {verifiedGogetters}K+
            </TileTitle>
            <TileDescription mobile={mobile ? 'true' : undefined}>
              Verified Part Timers
            </TileDescription>
          </Tile>
        </RowWrap>
        <RowWrap marginTop>
          <StyledLink href="https://www.weforum.org/events/world-economic-forum-on-asean/sessions/the-future-of-jobs-in-asean">
            <BrandWrap mobile={mobile ? 'true' : undefined}>
              <BrandIcon>
                <StaticImage
                  src={WEFLogo}
                  loading="eager"
                  placeholder="blurred"
                  style={{ width: mobile ? '53px' : '65px' }}
                  alt="WEF Logo"
                />
              </BrandIcon>
              <StyledDiv>
                <TileDescription brand>
                  The future of jobs with GoGet's technology
                </TileDescription>
                <StyledArrowIcon desktop={!mobile ? 'true' : undefined} />
              </StyledDiv>
            </BrandWrap>
          </StyledLink>
          <StyledLink href="https://www.uncdf.org/article/4628/goget-a-start-up-in-malaysia-shares-its-b40-challenge-experience">
            <BrandWrap mobile={mobile ? 'true' : undefined}>
              <BrandIcon>
                <StaticImage
                  src={UNCFDLogo}
                  loading="eager"
                  placeholder="blurred"
                  style={{ width: mobile ? '53px' : '65px' }}
                  alt="UNCFD Logo"
                />
              </BrandIcon>
              <StyledDiv>
                <TileDescription brand>
                  Creating financial inclusion through GoGet
                </TileDescription>
                <StyledArrowIcon desktop={!mobile ? 'true' : undefined} />
              </StyledDiv>
            </BrandWrap>
          </StyledLink>
        </RowWrap>
      </MainWrap>
    </Container>
  );
};

export default SocialProofSection;
